// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cabin-tsx": () => import("./../../../src/pages/cabin.tsx" /* webpackChunkName: "component---src-pages-cabin-tsx" */),
  "component---src-pages-discover-tsx": () => import("./../../../src/pages/discover.tsx" /* webpackChunkName: "component---src-pages-discover-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reviews-tsx": () => import("./../../../src/pages/reviews.tsx" /* webpackChunkName: "component---src-pages-reviews-tsx" */),
  "component---src-pages-story-tsx": () => import("./../../../src/pages/story.tsx" /* webpackChunkName: "component---src-pages-story-tsx" */)
}

